import { Question, Questionnaire, QuestionnaireWrapper, RequestForOfferQuestionnaireValidator, Tender,
  RequestForOfferQuestionnaireValidatorWeighted, OfferQuestionnaireValidatorFunctions, parseDecimalDk,
} from '@dims/components';
import { DateTime } from 'luxon';
import deliveryAgreementIds from '@/models/DeliveryAgreementIds';
import { TenderData0214 } from '@/models';

export default class RequestQuestionnaireValidator implements RequestForOfferQuestionnaireValidator, RequestForOfferQuestionnaireValidatorWeighted {
  private readonly questionnaire;
  private invalidWeightingValidationMessage = '';
  private readonly offerQuestionnaireValidatorFunctions;
  weightedValidator: RequestForOfferQuestionnaireValidatorWeighted = this;

  constructor(readonly tender: Tender, questionnaire: Questionnaire) {
    this.offerQuestionnaireValidatorFunctions = new OfferQuestionnaireValidatorFunctions();
    this.questionnaire = new QuestionnaireWrapper(
      this.tender.agreementConfiguration.questionnaires.requestForOffer,
      questionnaire,
    );
  }
  get incomplete() {
    return !this.questionnaire.content.complete;
  }
  minValue(question: Question): number | undefined {
    if (question.dataFieldType === 'Number') {
      if (question.questionId === deliveryAgreementIds.idChangeHoursKeyEmployees
        || question.questionId === deliveryAgreementIds.idChangeHoursConsultants) {
        return 20;
      }
      return 1;
    }
    return undefined;
  }

  maxValue(question: Question): number | undefined {
    if (question.questionId === deliveryAgreementIds.idChangeHoursKeyEmployees
      || question.questionId === deliveryAgreementIds.idChangeHoursConsultants) {
      return 50;
    }
    return undefined;
  }

  minDate(question: Question): string | undefined {
    if (question.questionId === deliveryAgreementIds.idDeliveryDate) {
      return this.tender.deadlineForTender ?? undefined;
    }
    if (question.dataFieldType === 'Date') {
      return DateTime.local().toISODate();
    }
    return undefined;
  }

  get weightQuestions() {
    const weightQuestionIds = [
      deliveryAgreementIds.idPlanWeight,
      deliveryAgreementIds.idCompetenceWeight,
      deliveryAgreementIds.idSolutionWeight,
      deliveryAgreementIds.idCooperationWeight,
    ];

    return this.questionnaire.content.questions.filter((q) => weightQuestionIds.includes(q.questionId));
  }

  weightQuestionText(questionId: string) {
    switch (questionId) {
      case deliveryAgreementIds.idPlanWeight:
        return 'tids-, aktivitets- og ressourceplan';
      case deliveryAgreementIds.idCompetenceWeight:
        return 'kompetencer';
      case deliveryAgreementIds.idSolutionWeight:
        return 'ydelsesspecifik løsningsbeskrivelse';
      case deliveryAgreementIds.idCooperationWeight:
        return 'samarbejde og metode';
      default:
        return '';
    }
  }

  get isSubcriteriaValid() {
    const data = this.tender.data as TenderData0214;
    return data.solutionType === 'Category1' || this.sumOfSubCriteria === 100;
  }

  get isAllNumericQuestionsValid() {
    return this.questionnaire.content.questions.every((q) => {
      if (q.dataFieldType === 'Number') {
        const answer = this.offerQuestionnaireValidatorFunctions.getQuestionAnswer(q);
        if (!answer) {
          return false;
        }
        const value = parseDecimalDk(answer.toString());
        const minValue = this.minValue(q);
        const maxValue = this.maxValue(q);
        if (value === null
          || (minValue && minValue > value)
          || (maxValue && maxValue < value)) {
          return false;
        }
      }
      return true;
    });
  }

  get sumOfSubCriteria() {
    const sum = (arr: number[]) => arr.reduce((previousq, q) => previousq + q, 0);
    const values = this.weightQuestions.map((q) => (q.answers?.[0] && q.answers[0].value !== '' ? parseFloat(q.answers[0].value) : 0));
    return sum(values);
  }

  get subCriteriaValidationMessage(): string {
    const sum = this.sumOfSubCriteria;
    return `Summen af delkriterierne skal være 100 point (Summen er ${sum})`;
  }

  get isQualityWeightingValid() {
    let validationMessage = '';

    const sortedWeightQuestions = this.weightQuestions;

    if (sortedWeightQuestions.length >= 2) {
      const checkWeightingOrder = (indexA: number, indexB: number) => {
        const aAnswer = this.offerQuestionnaireValidatorFunctions.getQuestionAnswer(sortedWeightQuestions[indexA]);
        const bAnswer = this.offerQuestionnaireValidatorFunctions.getQuestionAnswer(sortedWeightQuestions[indexB]);

        const aText = this.weightQuestionText(sortedWeightQuestions[indexA]?.questionId ?? '');
        const bText = this.weightQuestionText(sortedWeightQuestions[indexB]?.questionId ?? '');

        if (aAnswer < bAnswer) {
          validationMessage = `delkriterie '${bText}' er vægtet højere end delkriterie '${aText}'`;
          return false;
        }

        validationMessage = '';
        return true;
      };

      if (!checkWeightingOrder(0, 1)) {
        this.invalidWeightingValidationMessage = validationMessage;
        return false;
      }

      if (sortedWeightQuestions.length >= 3) {
        if (!checkWeightingOrder(0, 2)) {
          this.invalidWeightingValidationMessage = validationMessage;
          return false;
        }

        if (!checkWeightingOrder(1, 2)) {
          this.invalidWeightingValidationMessage = validationMessage;
          return false;
        }
      }

      if (sortedWeightQuestions.length === 4) {
        if (!checkWeightingOrder(0, 3)) {
          this.invalidWeightingValidationMessage = validationMessage;
          return false;
        }

        if (!checkWeightingOrder(1, 3)) {
          this.invalidWeightingValidationMessage = validationMessage;
          return false;
        }

        if (!checkWeightingOrder(2, 3)) {
          this.invalidWeightingValidationMessage = validationMessage;
          return false;
        }
      }
    }

    // See #24128. Only assign to "this.invalidWeightingValidationMessage" once, since
    // this is a watched value. And the Vue framework gets confused if the value
    // changes many times in the same function, and Vue will stop watching it.
    this.invalidWeightingValidationMessage = validationMessage;
    return true;
  }

  get qualityWeightingValidationMessage(): string {
    return `Delkriterier skal vægtes i faldende rækkefølge eller lige (${this.invalidWeightingValidationMessage}).`;
  }
}
